import React, { Component } from 'react';
import $ from 'jquery';
import Button from '../ui/button';
import * as style from './schedule.module.scss';

class Schedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			activeDay: 1,
			activeProgram: 0
		};
	}
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', () => {this.updateWindowDimensions()});
	}
	componentWillUnmount() {
		window.removeEventListener('resize', () => {this.updateWindowDimensions()});
	}
	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}
	setActiveDay(dayNum) {
		var newDay = parseInt(dayNum);
		var oldDay = parseInt(this.state.activeDay);

		if (newDay != oldDay) {
			this.setState({ activeDay: parseInt(newDay) });
			this.setState({ activeProgram: parseInt(0) });
		}
	}
	setActiveProgram(programNum, dayNum) {
		var newProgram = parseInt(programNum);
		var oldProgram = parseInt(this.state.activeProgram);

		if (newProgram != oldProgram) {
			this.setState({ activeProgram: parseInt(newProgram) });
		} else if(newProgram == oldProgram) {
			// TOOGLE
			this.setState({ activeProgram: parseInt(0) });
		}

	}

	render() {
		const width = (this.state) ? this.state.width: 0;

		let activeDay = this.state.activeDay;
		let activeProgram = this.state.activeProgram;

		/*
		$(document).ready(function() {
			console.log('SCHEDULE RENDER() READY...');
		});
		*/

		var componentRef = this;

		return (
			<div id="schedule-wrap">
				<h1 className={`${style.scheduleTitle} schedule-title`}>Event Schedule</h1>		
				<div id="schedule-wrap-inset">	
					<div
						id="schedule-days"
						className={`${style.scheduleDays} schedule-days`}
					>
						{this.props.schedule.length > 0 && this.props.schedule.map(function(item, i) {
							var currentIndex = parseInt(1 + i);

							var dayClass = '';
							if (activeDay == currentIndex) {
								dayClass = 'active';
							}

							return (
								<div
									className={`${style.scheduleDay} schedule-day`}
								>
									<h3
										className={`${style.dayTarget} day-target `+dayClass+` day-`+currentIndex}
										onClick={() => componentRef.setActiveDay(currentIndex)}
									>{item.day_title} <span><i class="fa-solid fa-arrow-right"></i></span></h3>
								</div>
							);
						})}
					</div>
					
					<div
						id="schedule-programs"
						className={`${style.schedulePrograms} schedule-programs`}
					>
						{this.props.schedule.length > 0 && this.props.schedule.map(function(item, i) {
							var dayIndex = parseInt(1 + i);

							var dayClass = '';
							if (activeDay == dayIndex) {
								dayClass = 'active';
							}

							return (
								<div
									className={`${style.scheduleDayPrograms} `+dayClass+` schedule-day-programs day-`+(dayIndex)}
								>
									{item.programs.length > 0 && item.programs.map(function(program, i) {
										var programIndex = parseInt(1 + i);

										var programClass = '';
										if (activeProgram == programIndex) {
											programClass = 'active';
										}

										return (
											<div
												className={`${style.program} program `+programClass+` program-`+(programIndex)+` day-`+(dayIndex)+` program-`+(dayIndex)+'-'+(programIndex)}
											>
												<div
													className={`${style.programHeader} program-header`}
													onClick={() => componentRef.setActiveProgram(programIndex, dayIndex)}
												>
													<div className='program-time'>
														{program.time}
													</div>
													<div className='program-title'>
														{program.title}
														<span className='plus'>
															<i class="fa-solid fa-plus"></i>
														</span>
													</div>
												</div>

												{activeProgram == programIndex &&
													<div className={`${style.programMeta} program-meta`}>
														<div className={`${style.programDescription} program-description`}>
															{program.description}
														</div>

														<div className={`${style.programSpeakers} program-speakers`}>
															{program.speakers.length > 0 && program.speakers.map(function(speaker, i) {
																return (
																	<div className={`${style.programSpeaker} program-speaker`}>
																		<div
																			className={`${style.speakerImage} speaker-image`}
																			style={{ backgroundImage: 'url('+speaker.bio_image+')'}}
																		>
																			{/* <img src={speaker.bio_image} /> */}
																		</div>

																		<div className={`${style.speakerMeta} speaker-meta`}>
																			<div className={`${style.speakerName} speaker-name`}>
																				{speaker.name}
																			</div>
																			<div className={`${style.speakerPosition} speaker-position`}>
																				{speaker.position}
																			</div>
																		</div>
																		
																		{/*
																		<div className={style.socialWrap}>
																			{item.social_media_link.uri &&
																				<a
																					href={item.social_media_link.uri}
																					className={`icon-social white-orange-hover`}
																					target="_blank"
																					rel="noopener"
																				>
																					<div className={`icon-twitter x-white`}></div>
																				</a>
																			}
																			{item.linkedin_link && item.linkedin_link.uri &&
																				<a
																					href={item.linkedin_link.uri}
																					className={`icon-social white-orange-hover fas fa-linkedin-in`}
																					target="_blank"
																					rel="noopener"
																				>
																					<span>LinkedIn</span>
																				</a>
																			}
																			{item.threads_link && item.threads_link.uri &&
																				<a
																					href={item.threads_link.uri}
																					className={`icon-social white-orange-hover fas fa-threads`}
																					target="_blank"
																					rel="noopener"
																				>
																					<span>Threads</span>
																				</a>
																			}
																		</div>
																		*/}
																	</div>
																);
															})}
														</div>
													</div>
												}
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
};

export default Schedule;
