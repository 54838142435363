import { graphql } from 'gatsby';
import jump from 'jump.js';
import React, { Component } from 'react';
// import VideoFeatureIntro from '../components/feature/videoFeatureIntro';
import HeaderIntro from '../components/conference/headerIntro';
import Schedule from '../components/conference/schedule';
import Faq from '../components/conference/faq';
import HeadTags from '../components/global/headTags';
import videofeatureMapDataToComponents from './videofeatureMapDataToComponents'; //
import FeatureLayout from '../layouts/feature-layout';
import { navigate } from 'gatsby-link';
import * as style from './conference.module.scss';

class Conference extends Component {
	constructor() {
		super();
		this.state = {
			startPage: false,
			isExiting: false,
			width: 0, 
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	
	componentWillReceiveProps(nextProps) {
		// issue where first page click doesn't transition properly, so manually doing
		// transition here.
		if (nextProps.transition && nextProps.transition.status === 'exiting') {
			this.setState({ isExiting: true });
		}
	}
	
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			isExiting: false,
		});
		setTimeout(this.startPage, 1000);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}
	
	startPage = () => {
		this.setState({ startPage: true });
	};
	
	render() {
		const {
			title,
			subheading,
			date,
			end_date,
			date_text,

			location_type,
			location_short_text,
			registration_link_text,
			registration_link_url,
			overview_text,

			schedule,
			faq,

			speakers,
			sponsors,

			meta_description,
			meta_image,
			short_description,
			featured_image,
			featured_video,
			video,
			video_embed,
			paragraphs,
		} = this.props.data.allItemsJson.edges[0].node;
		const props = this.props;
		const exploreClick = () => {
			jump('#content');
		};
		let headerImage = null;
		let headerTwitterImage = null;

		if(meta_image) {
			headerImage = meta_image.sizes.hero_md;
			headerTwitterImage = meta_image.sizes.hero_sm;
		} else {
			headerImage = featured_image.sizes.hero_md;
			headerTwitterImage = featured_image.sizes.hero_sm;
		}

		return (
			<FeatureLayout {...props} excludeParentFunctions>
				<div className="video-feature-content-wrapper">
					<HeadTags
						title={title}
						description={subheading}
						image={headerImage}
						imageTwitter={headerTwitterImage}
						location={props.location}
					/>

					<HeaderIntro 
						heading={title}
						description={subheading}
						backgroundImage={featured_image}

						date={date}
						end_date={end_date}
						date_text={date_text}
						locationType={location_type}
						locationText={location_short_text}

						registrationLinkText={registration_link_text}
						registrationLinkUrl={registration_link_url}
					/>


					<div className={style.overviewTextWrap}>
						<div className={style.overviewText} dangerouslySetInnerHTML={{__html: overview_text}} />	
					</div>

					{/* SCHEDULE */}
					{schedule.length > 0 &&
						<Schedule
							schedule={schedule}
						/>
					}

					{/* SPEAKERS */}
					<div className={style.speakersWrap}>
						<div id="speakers-header" className={style.speakersHeader}>
							<h1 className={style.speakersTitle}>
								Featured Speakers
							</h1>
						</div>

						<div className={style.speakersContainer}>
							<div className={style.speakersInnerContainer}>
								{speakers.length > 0 && speakers.map(function(item, i) {
									return (

										<div className={style.primaryItem}>
											<div
												className={style.bioImage} style={{ backgroundImage: 'url('+item.bio_image+')'}}
											>
												<br />
											</div>
											<h3
												className={style.staffName}
											>
												{item.name}
											</h3>
											<p 
												className={style.staffPosition}
											>
												{item.position}
											</p>
											
											<div className={style.socialWrap}>
												{item.social_media_link.uri &&
													<a
														href={item.social_media_link.uri}
														className={`icon-social white-orange-hover`}
														target="_blank"
														rel="noopener"
													>
														<div className={`icon-twitter x-white`}></div>
													</a>
												}
												{item.linkedin_link && item.linkedin_link.uri &&
													<a
														href={item.linkedin_link.uri}
														className={`icon-social white-orange-hover fas fa-linkedin-in`}
														target="_blank"
														rel="noopener"
													>
														<span>LinkedIn</span>
													</a>
												}
												{item.threads_link && item.threads_link.uri &&
													<a
														href={item.threads_link.uri}
														className={`icon-social white-orange-hover fas fa-threads`}
														target="_blank"
														rel="noopener"
													>
														<span>Threads</span>
													</a>
												}
											</div>
										</div>

									);
								})}
							</div>
						</div>
					</div>
					{/* */}

					{/* FAQ */}
					{faq.length > 0 &&
						<Faq
							faq={faq}
						/>
					}

					{/* SPONSORS */}
					<div
						className={style.sponsorsSection}
					>
						<h2 className={`${style.sectionHeading}`}>
							OUR PARTNERS
						</h2>
						<div
							className={`${style.sectionInset}`}
						> 
							{sponsors && sponsors.length > 0 && sponsors.map(function(item, i) {
									return (
										<div
											className={`${style.sectionItem} ${style.sponsorItem}`}
										>
											<a
												href={item.sponsor_url.uri}
												target="_blank"
											>
												{/* {item.title} */}
												{item.logo_image.sizes.original &&
													<img
														className={`${style.sponsorLogo}`}
														src={item.logo_image.sizes.original}
													/>
												}
											</a>
										</div>
									);
							})}
						</div>
					</div>
					{/* */}

					<div className="article-content">
						{/*
						<div className="text-block intro-text" dangerouslySetInnerHTML={{__html: short_description}} />
						*/}
						{/*
						{videofeatureMapDataToComponents(paragraphs)}
						*/}
					</div>

				</div>
			</FeatureLayout>
		);
	}
}

export default Conference;

// eslint-disable-next-line
export const ConferencePageQuery = graphql`
query conferencePageContentByPath(
	$path: String!
	$menuId: String!
	$idRegex: String!
	$isEs: Boolean!
	$blog: String!
	$translations: String!
  ) {
	allItemsJson(filter: { path: { eq: $path } }) {
	  edges {
		node {
		  path


		  meta_description
		  meta_image {
			sizes {
			  hero_md
			  hero_sm
			}
		  }

		  short_description

		  title
		  subheading

		  date
		  end_date
		  date_text

		  location_type
		  location_short_text
		  registration_link_text
		  registration_link_url

		  overview_text
		  
		  schedule {
		    day_title
			programs {
			  time
			  title
			  description
			  speakers {
				name
				description_plain
				social_media_link {
				uri
				title
				}
				linkedin_link {
				uri
				title
				}
				threads_link {
				uri
				title
				}
				bio_image
				position
			  }
			}
		  }
		  
		  faq {
		    question
			answer
		  }

		  speakers {
		  	id
			name
			description_plain
			social_media_link {
			  uri
			  title
			}
			linkedin_link {
			  uri
			  title
			}
			threads_link {
			  uri
			  title
			}
			bio_image
			position
		  }

		  sponsors {
		    name
			logo_image {
			  sizes {
			    original
			  }
			}
			sponsor_url {
			  uri
			}
		  }

		  related_challenge {
			id
			title
			path
		  }

		  featured_image {
			image {
			  sizes {
				hero_lg
				hero_md
				hero_sm
				original
			  }
			}
		  }

		}
	  }
	}
	social: allItemsJson(filter: { id: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
  
	# Index Content Data
	...headerFields @skip(if: $isEs)
	...footerFields @skip(if: $isEs)
	...headerFieldsEs @include(if: $isEs)
	...footerFieldsEs @include(if: $isEs)
	challenges: allItemsJson(
	  filter: {
		content_type: { regex: "/timeline|article|challenge/" }
		jsonId: { regex: $idRegex }
	  }
	) {
	  edges {
		node {
		  title
		  content_type
		  path
		  related_challenge {
			title
			path
		  }
		}
	  }
	}
	pages: allItemsJson(
	  filter: { content_type: { eq: "page" }, jsonId: { regex: $idRegex } }
	) {
	  edges {
		node {
		  title
		  content_type
		  path
		}
	  }
	}
	blog: allItemsJson(filter: { jsonId: { eq: $blog } }) {
	  edges {
		node {
		  title
		  content_type
		  path
		}
	  }
	}
	translations: allItemsJson(filter: { jsonId: { eq: $translations } }) {
	  edges {
		node {
		  translations {
			id
			key
			value
		  }
		}
	  }
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }
  
`;